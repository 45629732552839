.trends {
  margin-bottom: 50px;
  margin-top: 50px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    &__item {
      margin: 0 !important;
      width: calc(50% - 5px);
      cursor: pointer;
      text-align: left;
      height: 30px;
      line-height: 30px;
      border-radius: 15px !important;
      padding: 0 15px !important;
    }
  }
}