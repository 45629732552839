@import '../../../theme/scss/variables.scss';
@import './Gargantua-effects.scss';
@import './Gargantua-media.scss';

.gargantua {
    text-align: center;
    position: relative;

    .icons {
        position: absolute;
        display: flex;
        z-index: 1;
        justify-content: center;
        width: 100%;
        opacity: 0;
        animation: fadeIn 1s 6.5s forwards;
        -webkit-animation: fadeIn 1s 6.5s forwards;
        -moz-animation: fadeIn 1s 6.5s forwards;
        -o-animation: fadeIn 1s 6.5s forwards;
        -ms-animation: fadeIn 1s 6.5s forwards;

        &.fadeOut {
            opacity: 1;
            animation: fadeOut 1s 3.75s forwards;
            -webkit-animation: fadeOut 1s 3.75s forwards;
            -moz-animation: fadeOut 1s 3.75s forwards;
            -o-animation: fadeOut 1s 3.75s forwards;
            -ms-animation: fadeOut 1s 3.75s forwards;
        }


        img {
            width: 20px;
            margin: 80px 5px 0px;
        }
    }

    .imageContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 890px;

        .image-text {
            display: flex;
            justify-content: center;

            .text {
                position: absolute;
                top: 14%;
                width: 100%;
                z-index: 1;
                color: white;
                font-family: CandideExtraLight;
                font-size: 35px;
                line-height: 35px;
                letter-spacing: -2px;

                .first {
                    opacity: 0;
                    animation: textTranslate 1.5s 1s forwards;
                    -webkit-animation: textTranslate 1.5s 1s forwards;
                    -moz-animation: textTranslate 1.5s 1s forwards;
                    -o-animation: textTranslate 1.5s 1s forwards;
                    -ms-animation: textTranslate 1.5s 1s forwards;

                    &.fadeOut {
                        opacity: 1;
                        animation: fadeOut 1s 3.25s forwards;
                        -webkit-animation: fadeOut 1s 3.25s forwards;
                        -moz-animation: fadeOut 1s 3.25s forwards;
                        -o-animation: fadeOut 1s 3.25s forwards;
                        -ms-animation: fadeOut 1s 3.25s forwards;
                    }
                }

                .second {
                    opacity: 0;
                    animation: textTranslate 1.5s 2.5s forwards;
                    -webkit-animation: textTranslate 1.5s 2.5s forwards;
                    -moz-animation: textTranslate 1.5s 2.5s forwards;
                    -o-animation: textTranslate 1.5s 2.5s forwards;
                    -ms-animation: textTranslate 1.5s 2.5s forwards;

                    &.fadeOut {
                        opacity: 1;
                        animation: fadeOut 1s 2.75s forwards;
                        -webkit-animation: fadeOut 1s 2.75s forwards;
                        -moz-animation: fadeOut 1s 2.75s forwards;
                        -o-animation: fadeOut 1s 2.75s forwards;
                        -ms-animation: fadeOut 1s 2.75s forwards;
                    }
                }
            }

            .title {
                $fontHeight: 90px;
                font-family: Disco;
                position: absolute;
                top: calc(22%);
                line-height: 0.1;
                z-index: 1;
                font-size: $fontHeight;
                color: $font-light;
                opacity: 0;
                animation: fadeIn 3s 3.5s forwards;
                -webkit-animation: fadeIn 3s 3.5s forwards;
                -moz-animation: fadeIn 3s 3.5s forwards;
                -o-animation: fadeIn 3s 3.5s forwards;
                -ms-animation: fadeIn 3s 3.5s forwards;
                text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;

                &.fadeOut {
                    opacity: 1;
                    animation: fadeOut 2s 4.25s forwards;
                    -webkit-animation: fadeOut 2s 4.25s forwards;
                    -moz-animation: fadeOut 2s 4.25s forwards;
                    -o-animation: fadeOut 2s 4.25s forwards;
                    -ms-animation: fadeOut 2s 4.25s forwards;
                }

                &.space {
                    letter-spacing: 45px;
                    margin-left: 45px;
                    height: 293px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    div {
                        line-height: 1;
                        min-height: $fontHeight;
                    }
                }
            }

            .fade-in-image {
                width: 100vw;
                position: absolute;
                top: 0;
                animation: fadeIn 5s;
                -webkit-animation: fadeIn 5s;
                -moz-animation: fadeIn 5s;
                -o-animation: fadeIn 5s;
                -ms-animation: fadeIn 5s;

                &.fadeOut {
                    opacity: 1;
                    animation: fadeOut 1.5s 5.25s forwards;
                    -webkit-animation: fadeOut 1.5s 5.25s forwards;
                    -moz-animation: fadeOut 1.5s 5.25s forwards;
                    -o-animation: fadeOut 1.5s 5.25s forwards;
                    -ms-animation: fadeOut 1.5s 5.25s forwards;
                }

                .image {
                    position: relative;
                    max-width: 100%;
                    top: 0;
                    height: 737px;
                    margin: auto;
                    object-fit: cover;


                }
            }
        }

        .actions-text {
            color: $font-light;
            font-family: CandideExtraLight;
            font-size: 22px;
            line-height: 1.5;
            position: relative;
            top: 22%;

            .first {
                opacity: 0;
                animation: fadeIn 1s 4.5s forwards;
                -webkit-animation: fadeIn 1s 4.5s forwards;
                -moz-animation: fadeIn 1s 4.5s forwards;
                -o-animation: fadeIn 1s 4.5s forwards;
                -ms-animation: fadeIn 1s 4.5s forwards;

                &.fadeOut {
                    opacity: 1;
                    animation: fadeOut 1s 2.25s forwards;
                    -webkit-animation: fadeOut 1s 2.25s forwards;
                    -moz-animation: fadeOut 1s 2.25s forwards;
                    -o-animation: fadeOut 1s 2.25s forwards;
                    -ms-animation: fadeOut 1s 2.25s forwards;
                }
            }

            .second {
                opacity: 0;
                animation: fadeIn 1s 5s forwards;
                -webkit-animation: fadeIn 1s 5s forwards;
                -moz-animation: fadeIn 1s 5s forwards;
                -o-animation: fadeIn 1s 5s forwards;
                -ms-animation: fadeIn 1s 5s forwards;

                &.fadeOut {
                    opacity: 1;
                    animation: fadeOut 1s 1.75s forwards;
                    -webkit-animation: fadeOut 1s 1.75s forwards;
                    -moz-animation: fadeOut 1s 1.75s forwards;
                    -o-animation: fadeOut 1s 1.75s forwards;
                    -ms-animation: fadeOut 1s 1.75s forwards;
                }
            }

            .third {
                opacity: 0;
                animation: fadeIn 1s 5.5s forwards;
                -webkit-animation: fadeIn 1s 5.5s forwards;
                -moz-animation: fadeIn 1s 5.5s forwards;
                -o-animation: fadeIn 1s 5.5s forwards;
                -ms-animation: fadeIn 1s 5.5s forwards;

                &.fadeOut {
                    opacity: 1;
                    animation: fadeOut 1s 1.25s forwards;
                    -webkit-animation: fadeOut 1s 1.25s forwards;
                    -moz-animation: fadeOut 1s 1.25s forwards;
                    -o-animation: fadeOut 1s 1.25s forwards;
                    -ms-animation: fadeOut 1s 1.25s forwards;
                }
            }

            .fourth {
                opacity: 0;
                animation: fadeIn 1s 6s forwards;
                -webkit-animation: fadeIn 1s 6s forwards;
                -moz-animation: fadeIn 1s 6s forwards;
                -o-animation: fadeIn 1s 6s forwards;
                -ms-animation: fadeIn 1s 6s forwards;

                &.fadeOut {
                    opacity: 1;
                    animation: fadeOut 1s .75s forwards;
                    -webkit-animation: fadeOut 1s .75s forwards;
                    -moz-animation: fadeOut 1s .75s forwards;
                    -o-animation: fadeOut 1s .75s forwards;
                    -ms-animation: fadeOut 1s .75s forwards;
                }

            }
        }

        .scroll-buttons {
            display: flex;
            justify-content: space-between;
            top: 25%;
            position: relative;
            color: $font-grey;
            font-size: 32px;
            line-height: 2;

            &.mobile {
                display: block;
            }

            .icon {
                margin: 0 20px;

                &:hover {
                    color: $font-light;
                }
            }

            .actions {
                position: relative;

                button {
                    background-color: $background-dark;
                    color: $font-light;
                    border: 1px solid $background-light;
                    font-family: Disco;
                    border-radius: 8px;
                    padding: 15px 40px;
                    font-size: 20px;
                    width: unset;
                    letter-spacing: 10px;
                    margin: 0 0 30px;
                    opacity: 0;
                    animation: glowing 1.75s 7.5s forwards;
                    -webkit-animation: glowing 1.75s 7.5s forwards;
                    -moz-animation: glowing 1.75s 7.5s forwards;
                    -o-animation: glowing 1.75s 7.5s forwards;
                    -ms-animation: glowing 1.75s 7.5s forwards;

                    &.fadeOut {
                        opacity: 1;
                        animation: fadeOut 1.5s forwards;
                        -webkit-animation: fadeOut 1.5s forwards;
                        -moz-animation: fadeOut 1.5s forwards;
                        -o-animation: fadeOut 1.5s forwards;
                        -ms-animation: fadeOut 1.5s forwards;
                    }


                }
            }
        }
    }


    .road-map {
        display: flex;
        justify-content: center;
        top: -110px;
        position: relative;

        .image {
            width: 160px;
            filter: brightness(50%);
            margin: 0 -20px;

            &.checked {
                filter: brightness(100%);
            }
        }

        &.mobile {
            .image {
                width: 140px;
            }
        }
    }
}