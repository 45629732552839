.toggle-heart {
  display: inline-block;
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  color: #aab8c2;
  font-size: 1em;
  text-align: center;

  .icon {
    position: relative;
  }

  &.checked {
    .icon {
      color: #e2264d;
      will-change: font-size;
      animation: heart 1s cubic-bezier(.17, .89, .32, 1.45);
    }
  }
}

@keyframes heart {

  0%,
  15% {
    font-size: 0;

  }

  30% {
    left: -1px;
  }
}