@import "../../../theme/scss/variables.scss";

.share-link {
    

 
    .dropdown {
        margin: auto 0;
        //position: relative;
        display: inline-flex;
        flex-direction: row;
        
      
        .divider {
            display: none;
        }

        .icon {
            font-size: 18px;
        }

        .menu {
            
            background-color: $background-dark;
           
            left: -25px;
        }
    }
}