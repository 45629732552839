html {
  font-size: 16px;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: $font-light;
  //background-color: $background;
  font-size: 16px;
}

#root {
  height: 100%;
}

header {
  height: $header-height;
}

main {
  padding: 30px 0 !important;
  margin: $header-height auto;
}

footer {

}