@import "../../theme/scss/variables.scss";

@media only screen and (max-width: 767px) {
    .ui.container {
        .profileForm {
            width: auto !important;
            margin-left: 1em !important;
            margin-right: 1em !important;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ui.container {
        .profileForm {
            width: 723px;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
    .ui.container {
        .profileForm {
            width: 933px;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
}

@media only screen and (min-width: 1280px) {
    .ui.container {
        .profileForm {
            width: 1280px;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
}