@import "../../theme/scss/variables.scss";

.emotionsList {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  text-align: left;

  .emotionItem {
    position: relative;
    width: calc(50% - 5px);

    &__label {
      margin-bottom: 0 !important;

      .icon {
        margin: 0 !important;
      }

      &__button {
        position: absolute;
        top: 0;
        height: 100% !important;
        padding: 0 5px !important;
        display: flex;
        align-items: center;
        opacity: 1;
        color: $button;

        &__minus {
          left: 4px;
        }
    
        &__plus {
          right: 4px;
        }
      }

      &__value {
        display: inline-block;
        margin: 0;
        font-size: 12px;
      }

      &__percent {
        height: 10px;
        background: linear-gradient(to right, rgb(52, 232, 158), rgb(15, 52, 67));
        margin-top: 5px;
        border-radius: 5px;


        &__value {
          width: 90%;
          background: #eee;
          height: 100%;
          float: right;
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
}