@import "../../theme/scss/variables.scss";

.profileForm {
    display: flex !important;
    flex-direction: column;
    color: $font-light;

    .tabs {
        display: flex;

        .tab-item {
            width: 50%;
            text-align: center;
            cursor: pointer;
            padding: 20px 0;
            font-size: 16px;
            border-bottom: 1px solid $background-grey;

            &.active {
                border-bottom: 4px solid $background-grey;
                font-weight: 600;
            }

            .icon {
                margin-right: 10px;
            }
        }
    }

    .ui.input {
        width: 100%;

        input {
            color: $font-light;
            background-color: inherit;
        }
    }
}