@media only screen and (max-width: 767px) {
    .gargantua {
        .imageContainer {
            .image-text {
                .title {
                    $fontHeight: 50px;
                    font-size: $fontHeight !important;

                    &.space {
                        flex-direction: column;

                        div {
                            min-height: $fontHeight !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .gargantua {
        .imageContainer {
            .image-text {
                .title {
                    &.space {
                        flex-direction: column;
                    }
                }
            }
        }
    }

}

@media only screen and (min-width: 992px) and (max-width: 1279px) {}

@media only screen and (min-width: 1280px) {}