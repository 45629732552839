#menuToggle {
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  height: 16px;
  width: 14px;

  &:hover {
    cursor: pointer;
  }

  span {
    display: block;
    width: 14px;
    height: 2px;
    margin-bottom: 10px;
    position: relative;
    background: #616161;
    border-radius: 3px;
  }

  &.open {
    span {
      background: #616161;
    }
  }
}

#menuToggle {
  span {
    position: absolute;
    margin-bottom: 0;
    transition: all .3s linear;

    &:first-child {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: calc(50% - 1px);
    }

    &:last-child {
      bottom: 0px;
    }

    &:first-child,
    &:last-child {
      transition-delay: .3s;
    }

    &:nth-child(2),
    &:nth-child(3) {
      transition-delay: 0;
    }
  }

  &.open {
    span {
      &:first-child,
      &:last-child {
        opacity: 0;
        transition-delay: 0s;
      }

      &:first-child {
        top: calc(50% - 2px);
      }

      &:last-child {
        bottom: calc(50% - 2px);
      }

      &:nth-child(2),
      &:nth-child(3) {
        transition-delay: .3s;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }

}