.ui {
  font-family: 'Courier New', Courier, monospace !important;

  &.container {
    &.fluid {
      max-width: $container-max-width !important;
      padding: 0 20px !important;
      margin: 0 auto !important;
    }
  }

  &.segment {
    border: 4px solid $button !important;
    border-radius: 0;
    box-shadow: none;

    &.noborder {
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
    }
  }

  // &.icon {
  //   &.input {
  //     >i.icon {
  //       top: 17px !important;
  //     }
  //   }
  // }

  &.header {
    font-family: 'Courier New', Courier, monospace;
  }

  &.input {
    width: 100%;
  }

  &.menu {
    border: none;
    box-shadow: none;
  }

  &.vertical.menu {
    box-shadow: none;
  }

  &.button {
    text-transform: uppercase;

    &.big {
      height: 80px;
      line-height: 80px;
      font-size: 26px;
      padding: 0;
    }

    &.filled {
      background-color: $button;
      color: $background;
    } 

    &.outlined {
      background-color: transparent;
      color: $button;
      border: 4px solid $button;
      padding: 0;

      &.big {
        line-height: 72px;
      }
    }

    &.aslink {
      background-color: transparent;
      color: $button;
      border: 0;
      padding: 0;
      box-shadow: none !important;
    }

    &.label {
      width: 100%;
      height: auto; //64px
      background-color: transparent;
      color: $button;
      border: 4px solid $button;
      margin-bottom: 5px;
      font-size: 14px !important;
    }
  }

  &.input {
    &>input {
      height: 64px;
    }
  } 

  &.accordion {
    .header {
      .dropdown {
        &.icon {
          float: right;
          margin: 0;
        }
      }
    }
  }

  &.card {
    box-shadow: none !important;
    border: 4px solid $button;
    border-radius: 0 !important;

    &.noborder {
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
    }
  }

  &.message {
    box-shadow: none !important;
    border: 4px solid $button;
    border-radius: 0 !important;

    &.attached {
      border-bottom: 0;
    }

    &.noborder {
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
      background-color: transparent !important;
    }
  }

  .label {
    >.detail {
      clear: both;
      width: 100%;
      text-align: center;
      margin-left: 0;
      opacity: 1;
    }
  }
}

.pushable {
  padding: 30px 0 !important;
  margin: $header-height auto !important;
  overflow: hidden !important;
  height: auto !important;
  min-height: 100% !important;
}

h1.ui.header {
  font-size: 32px !important;
}

h2.ui.header {
  font-size: 24px !important;
}

h3.ui.header {
  font-size: 21px !important;
}

h4.ui.header {

}

h5.ui.header {

}

h6.ui.header {

}