@import "../../theme/scss/variables.scss";

.topheader {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $background;
  z-index: 999999;

  &__content {
    display: flex;
    align-content: space-between;
    align-items: center;
    gap: 20px;

    &__logo {
      flex-grow: 1;

      img {
        height: 50px;
      }
    }
  }
}