.pointer {
  cursor: pointer;
}

.hide {
  display: none !important;
}

.bold {
  font-weight: 600;
}

.mt {
  &--none {
    margin-top: 0 !important;
  }
  
  &--simple {
    margin-top: 10px !important;
  }

  &--double {
    margin-top: 20px !important;
  }
}

.padding {
  &--20 {
    padding: 20px !important;
  }
}