@keyframes textTranslate {
    0% {
        opacity: 0;
        transform: translateY(-60px);
    }

    /* 75% {
        opacity: 0.5;
    } */

    100% {
        opacity: 1;
        transform: translate(0px);
    }

}

@-moz-keyframes textTranslate {
    0% {
        opacity: 0;
        transform: translateY(-60px);
    }

    100% {
        opacity: 1;
        transform: translate(0px);
    }

}

@-webkit-keyframes textTranslate {
    0% {
        opacity: 0;
        transform: translateY(-60px);
    }

    100% {
        opacity: 1;
        transform: translate(0px);
    }

}

@-o-keyframes textTranslate {
    0% {
        opacity: 0;
        transform: translateY(-60px);
    }

    100% {
        opacity: 1;
        transform: translate(0px);
    }

}

@-ms-keyframes textTranslate {
    0% {
        opacity: 0;
        transform: translateY(-60px);
    }

    100% {
        opacity: 1;
        transform: translate(0px);
    }

}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes glowing {
    0% {
        opacity: 0;
        box-shadow: 0 0 5px $background-light;
    }

    25% {
        opacity: 1;
        box-shadow: 0 0 5px $background-light;
    }

    66% {
        opacity: 1;
        box-shadow: 0 0 20px $background-light;
    }

    100% {
        opacity: 1;
        box-shadow: 0 0 5px $background-light;
    }
}

@-moz-keyframes glowing {
    0% {
        opacity: 0;
        box-shadow: 0 0 5px $background-light;
    }

    25% {
        opacity: 1;
        box-shadow: 0 0 5px $background-light;
    }

    66% {
        opacity: 1;
        box-shadow: 0 0 20px $background-light;
    }

    100% {
        opacity: 1;
        box-shadow: 0 0 5px $background-light;
    }
}

@-webkit-keyframes glowing {
    0% {
        opacity: 0;
        box-shadow: 0 0 5px $background-light;
    }

    25% {
        opacity: 1;
        box-shadow: 0 0 5px $background-light;
    }

    66% {
        opacity: 1;
        box-shadow: 0 0 20px $background-light;
    }

    100% {
        opacity: 1;
        box-shadow: 0 0 5px $background-light;
    }
}

@-o-keyframes glowing {
    0% {
        opacity: 0;
        box-shadow: 0 0 5px $background-light;
    }

    25% {
        opacity: 1;
        box-shadow: 0 0 5px $background-light;
    }

    66% {
        opacity: 1;
        box-shadow: 0 0 20px $background-light;
    }

    100% {
        opacity: 1;
        box-shadow: 0 0 5px $background-light;
    }
}

@-ms-keyframes glowing {
    0% {
        opacity: 0;
        box-shadow: 0 0 5px $background-light;
    }

    25% {
        opacity: 1;
        box-shadow: 0 0 5px $background-light;
    }

    66% {
        opacity: 1;
        box-shadow: 0 0 20px $background-light;
    }

    100% {
        opacity: 1;
        box-shadow: 0 0 5px $background-light;
    }
}