$font-light: #fff;
$font-grey: #a2a2a2;
$font-dark: #000;

$background-light: #fff;
$background-grey-light: #fafafa;
$background-dark: #000;
$background-grey: #a2a2a2;
$background-grey-dark: #272727;

$border-dark: #000;

$action: #ff00ff;
$danger: #ed4956;

//NEW
$background: #181A20;
$titles: #FFFFFF;
$text: #E0E0E0;
$menus: #1F222A;
$elements: #35383F;
$buttons: #4353FF;
$feat: #F777FA;
$placeholder: #757575;

//LAST DESIGN
$background: #FFF;
$button: #000;