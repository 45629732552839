@import "../../theme/scss/variables.scss";

.cardplanet {
  border: 4px solid $button !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  &__tags {
    position: absolute;
    width: 100%;
    z-index: 999;
    color: #fff;
  }
}